export const CraftyUuids = {
    ServiceUuid: "00000001-4c45-4b43-4942-265a524f5453",
    TemperatureUuid: "00000011-4c45-4b43-4942-265a524f5453", 
    SetPointUuid: "00000021-4c45-4b43-4942-265a524f5453",
    BoostUuid: "00000031-4c45-4b43-4942-265a524f5453",
    BatteryUuid: "00000041-4c45-4b43-4942-265a524f5453",
    LedUuid: "00000051-4c45-4b43-4942-265a524f5453",
    MetaDataUuid: "00000002-4c45-4b43-4942-265a524f5453",
    ModelUuid: "00000022-4c45-4b43-4942-265a524f5453",
    VersionUuid: "00000032-4c45-4b43-4942-265a524f5453",
    SerialUuid: "00000052-4c45-4b43-4942-265a524f5453",
    MiscDataUuid: "00000003-4c45-4b43-4942-265a524f5453",
    HoursOfOperationUuid: "00000023-4c45-4b43-4942-265a524f5453",
    SettingsUuid: "000001c3-4c45-4b43-4942-265a524f5453",
    PowerUuid: "00000063-4c45-4b43-4942-265a524f5453",
    ChargingUuid: "000000a3-4c45-4b43-4942-265a524f5453",
    PowerBoostHeatStateUuid: "00000093-4c45-4b43-4942-265a524f5453",
    Battery: {
        RemainingUuid: "00000153-4c45-4b43-4942-265a524f5453",
        TotalUuid: "00000143-4c45-4b43-4942-265a524f5453",
        DesignUuid: "00000183-4c45-4b43-4942-265a524f5453",
        DischargeCyclesUuid: "00000163-4c45-4b43-4942-265a524f5453",
        ChargeCyclesUuid: "00000173-4c45-4b43-4942-265a524f5453",
    },
    otherUuids: [
        { uuid: '00000153-4c45-4b43-4942-265a524f5453', label: 'Battery Remaining', type: 'hex', suffix: 'mah', notify: false},
        { uuid: '00000143-4c45-4b43-4942-265a524f5453', label: 'Battery Capacity', type: 'hex', suffix: 'mah' },
        { uuid: '00000183-4c45-4b43-4942-265a524f5453', label: 'Battery Design Capacity', type: 'hex', suffix: 'mah' },
        { uuid: '00000163-4c45-4b43-4942-265a524f5453', label: 'Discharge Cycles', type: 'hex' },
        { uuid: '00000173-4c45-4b43-4942-265a524f5453', label: 'Charge Cycles', type: 'hex' },
        { uuid: '00000033-4c45-4b43-4942-265a524f5453', label: 'Hardware ID', type: 'ascii' },
        { uuid: '00000053-4c45-4b43-4942-265a524f5453', label: 'SN Hardware', type: 'ascii' },
        { uuid: '00000042-4c45-4b43-4942-265a524f5453', label: 'Bluetooth Address', type: 'hex' },
        { uuid: '00000043-4c45-4b43-4942-265a524f5453', label: 'PCB Version', type: 'hex' },
        { uuid: '000000f3-4c45-4b43-4942-265a524f5453', label: 'Current Temperature (PT1000)', type: 'hex', suffix: '°c', divider: 10},
        { uuid: '00000103-4c45-4b43-4942-265a524f5453', label: 'Adjusted Current Temperature (PT1000)', type: 'hex', suffix: '°c', divider: 10},
        { uuid: '00000113-4c45-4b43-4942-265a524f5453', label: 'Accu Temperature', type: 'hex', suffix: '°c', divider: 10 },
        { uuid: '00000123-4c45-4b43-4942-265a524f5453', label: 'Accu Temperature Min', type: 'hex', suffix: '°c', divider: 10 },
        { uuid: '00000133-4c45-4b43-4942-265a524f5453', label: 'Accu Temperature Max', type: 'hex', suffix: '°c', divider: 10 },
        { uuid: '00000013-4c45-4b43-4942-265a524f5453', label: 'Operating Time Accu', type: 'hex', suffix: 'h', divider: 10 },
        { uuid: '000000b3-4c45-4b43-4942-265a524f5453', label: 'Voltage Accu', type: 'hex', suffix: 'mv' },
        { uuid: '000000c3-4c45-4b43-4942-265a524f5453', label: 'Voltage Mains', type: 'hex', suffix: 'mv' },
        { uuid: '000000d3-4c45-4b43-4942-265a524f5453', label: 'Voltage Heating', type: 'hex', suffix: 'mv' },
        { uuid: '000000e3-4c45-4b43-4942-265a524f5453', label: 'Current Accu', type: 'hex', suffix: 'ma' },
    ],
}

//    '00000072-4c45-4b43-4942-265a524f5453': { label: 'ble firmware version', type: 'ascii' },
//    '000001e3-4c45-4b43-4942-265a524f5453': { label: 'poweron time device', type: 'hex', suffix: 'h' },
//    '00000062-4c45-4b43-4942-265a524f5453': { label: 'operating time accu 2', type: 'hex', suffix: 'h' },
